import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";

const SdkPage = () => {
  return (
    <Layout>
      <Seo title="Sdk" pathname="/sdk" />
      <main>Sdk Page</main>
    </Layout>
  );
};

export default SdkPage;
